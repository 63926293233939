export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '1.48.0',
    name: 'orga-app-desktop-client',
    versionDate: '2024-10-11T13:12:14.603Z',
    description: 'ORGArena ORGA App',
    gitCommitHash: 'ge9637117',
    gitCommitDate: '2024-10-11T11:53:55.000Z',
    versionLong: '1.48.0-ge9637117',
    gitTag: 'v1.48.0',
};
export default versions;
