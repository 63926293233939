/**
 * orga app | kommunikation
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MessageResponse } from '../model/messageResponse';
import { MitteilungsVorlageDatenRequest } from '../model/mitteilungsVorlageDatenRequest';
import { MitteilungsVorlageRequest } from '../model/mitteilungsVorlageRequest';
import { MitteilungsVorlageRolleMultiRequest } from '../model/mitteilungsVorlageRolleMultiRequest';
import { MitteilungsVorlageTypMultiRequest } from '../model/mitteilungsVorlageTypMultiRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MitteilungsVorlageCommandService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostMitteilungsVorlageAktualisieren (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageAktualisieren(body?: MitteilungsVorlageDatenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageAktualisieren(body?: MitteilungsVorlageDatenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageAktualisieren(body?: MitteilungsVorlageDatenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageAktualisieren(body?: MitteilungsVorlageDatenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMitteilungsVorlageAnlegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageAnlegen(body?: MitteilungsVorlageDatenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageAnlegen(body?: MitteilungsVorlageDatenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageAnlegen(body?: MitteilungsVorlageDatenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageAnlegen(body?: MitteilungsVorlageDatenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/anlegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMitteilungsVorlageEmpfaengerRollenEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageEmpfaengerRollenEntfernen(body?: MitteilungsVorlageRolleMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageEmpfaengerRollenEntfernen(body?: MitteilungsVorlageRolleMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageEmpfaengerRollenEntfernen(body?: MitteilungsVorlageRolleMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageEmpfaengerRollenEntfernen(body?: MitteilungsVorlageRolleMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/rollen/empfaenger/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMitteilungsVorlageEmpfaengerRollenHinzufuegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageEmpfaengerRollenHinzufuegen(body?: MitteilungsVorlageRolleMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageEmpfaengerRollenHinzufuegen(body?: MitteilungsVorlageRolleMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageEmpfaengerRollenHinzufuegen(body?: MitteilungsVorlageRolleMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageEmpfaengerRollenHinzufuegen(body?: MitteilungsVorlageRolleMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/rollen/empfaenger/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMitteilungsVorlageEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageEntfernen(body?: MitteilungsVorlageRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageEntfernen(body?: MitteilungsVorlageRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageEntfernen(body?: MitteilungsVorlageRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageEntfernen(body?: MitteilungsVorlageRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMitteilungsVorlageKopieren (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageKopieren(body?: MitteilungsVorlageRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageKopieren(body?: MitteilungsVorlageRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageKopieren(body?: MitteilungsVorlageRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageKopieren(body?: MitteilungsVorlageRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/kopieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMitteilungsVorlageVeranstaltungsTypenEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageVeranstaltungsTypenEntfernen(body?: MitteilungsVorlageTypMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageVeranstaltungsTypenEntfernen(body?: MitteilungsVorlageTypMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageVeranstaltungsTypenEntfernen(body?: MitteilungsVorlageTypMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageVeranstaltungsTypenEntfernen(body?: MitteilungsVorlageTypMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/veranstaltungstypen/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMitteilungsVorlageVeranstaltungsTypenHinzufuegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMitteilungsVorlageVeranstaltungsTypenHinzufuegen(body?: MitteilungsVorlageTypMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postMitteilungsVorlageVeranstaltungsTypenHinzufuegen(body?: MitteilungsVorlageTypMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postMitteilungsVorlageVeranstaltungsTypenHinzufuegen(body?: MitteilungsVorlageTypMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postMitteilungsVorlageVeranstaltungsTypenHinzufuegen(body?: MitteilungsVorlageTypMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/kommunikation/mitteilungsvorlagen/veranstaltungstypen/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
