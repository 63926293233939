import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AufgabenCommandService } from './api/aufgabenCommand.service';
import { ChecklisteCommandService } from './api/checklisteCommand.service';
import { ChecklisteQueryService } from './api/checklisteQuery.service';
import { DokumenteCommandService } from './api/dokumenteCommand.service';
import { DokumenteQueryService } from './api/dokumenteQuery.service';
import { VorlagenQueryService } from './api/vorlagenQuery.service';
import { VorlagenTemplatesService } from './api/vorlagenTemplates.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AufgabenCommandService,
    ChecklisteCommandService,
    ChecklisteQueryService,
    DokumenteCommandService,
    DokumenteQueryService,
    VorlagenQueryService,
    VorlagenTemplatesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
