/**
 * orga app | qschecks
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FunktionsbereichPruefplaene } from '../model/funktionsbereichPruefplaene';
import { PrueflaufDetail } from '../model/prueflaufDetail';
import { SaisonFilterOption } from '../model/saisonFilterOption';
import { SaisonUebersicht } from '../model/saisonUebersicht';
import { SaisonUebersichtRequest } from '../model/saisonUebersichtRequest';
import { VeranstaltungUebersicht } from '../model/veranstaltungUebersicht';
import { VeranstaltungUebersichtRequest } from '../model/veranstaltungUebersichtRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ControllingQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetFunktionsbereichePruefplaeneControlling (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichePruefplaeneControlling(observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichPruefplaene>>;
    public getFunktionsbereichePruefplaeneControlling(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichPruefplaene>>>;
    public getFunktionsbereichePruefplaeneControlling(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichPruefplaene>>>;
    public getFunktionsbereichePruefplaeneControlling(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichPruefplaene>>('get',`${this.basePath}/api/qschecks/query/controlling/funktionsbereiche/pruefplaene`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPrueflaufControlling (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param prueflauf_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrueflaufControlling(prueflauf_id: string, observe?: 'body', reportProgress?: boolean): Observable<PrueflaufDetail>;
    public getPrueflaufControlling(prueflauf_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrueflaufDetail>>;
    public getPrueflaufControlling(prueflauf_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrueflaufDetail>>;
    public getPrueflaufControlling(prueflauf_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prueflauf_id === null || prueflauf_id === undefined) {
            throw new Error('Required parameter prueflauf_id was null or undefined when calling getPrueflaufControlling.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PrueflaufDetail>('get',`${this.basePath}/api/qschecks/query/controlling/prueflauf/${encodeURIComponent(String(prueflauf_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPrueflaufExcel (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param prueflauf_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrueflaufExcel(prueflauf_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPrueflaufExcel(prueflauf_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPrueflaufExcel(prueflauf_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPrueflaufExcel(prueflauf_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prueflauf_id === null || prueflauf_id === undefined) {
            throw new Error('Required parameter prueflauf_id was null or undefined when calling getPrueflaufExcel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/qschecks/query/controlling/prueflauf/${encodeURIComponent(String(prueflauf_id))}/excel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanSaisonFilter (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param pruefplan_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanSaisonFilter(pruefplan_id: string, observe?: 'body', reportProgress?: boolean): Observable<SaisonFilterOption>;
    public getPruefplanSaisonFilter(pruefplan_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SaisonFilterOption>>;
    public getPruefplanSaisonFilter(pruefplan_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SaisonFilterOption>>;
    public getPruefplanSaisonFilter(pruefplan_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanSaisonFilter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SaisonFilterOption>('get',`${this.basePath}/api/qschecks/query/controlling/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/saisonfilter`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetSaisonUebersicht (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaisonUebersicht(body?: SaisonUebersichtRequest, observe?: 'body', reportProgress?: boolean): Observable<SaisonUebersicht>;
    public getSaisonUebersicht(body?: SaisonUebersichtRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SaisonUebersicht>>;
    public getSaisonUebersicht(body?: SaisonUebersichtRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SaisonUebersicht>>;
    public getSaisonUebersicht(body?: SaisonUebersichtRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SaisonUebersicht>('post',`${this.basePath}/api/qschecks/query/controlling/saison/uebersicht`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetSaisonUebersichtExcel (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSaisonUebersichtExcel(body?: SaisonUebersichtRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getSaisonUebersichtExcel(body?: SaisonUebersichtRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getSaisonUebersichtExcel(body?: SaisonUebersichtRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getSaisonUebersichtExcel(body?: SaisonUebersichtRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/qschecks/query/controlling/saison/uebersicht/excel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungExcel (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungExcel(body?: VeranstaltungUebersichtRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungExcel(body?: VeranstaltungUebersichtRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungExcel(body?: VeranstaltungUebersichtRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungExcel(body?: VeranstaltungUebersichtRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/qschecks/query/controlling/veranstaltung/excel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungUebersicht (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungUebersicht(body?: VeranstaltungUebersichtRequest, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungUebersicht>;
    public getVeranstaltungUebersicht(body?: VeranstaltungUebersichtRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungUebersicht>>;
    public getVeranstaltungUebersicht(body?: VeranstaltungUebersichtRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungUebersicht>>;
    public getVeranstaltungUebersicht(body?: VeranstaltungUebersichtRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VeranstaltungUebersicht>('post',`${this.basePath}/api/qschecks/query/controlling/veranstaltung/uebersicht`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
