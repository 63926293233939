/**
 * orga app | benutzerverwaltung
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Funktionsbereich } from '../model/funktionsbereich';
import { FunktionsbereichAdminBenutzer } from '../model/funktionsbereichAdminBenutzer';
import { FunktionsbereichDetail } from '../model/funktionsbereichDetail';
import { FunktionsbereichFreigabeRolle } from '../model/funktionsbereichFreigabeRolle';
import { FunktionsbereichRollen } from '../model/funktionsbereichRollen';
import { Rolle } from '../model/rolle';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FunktionsbereicheQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetFunktionsbereichAdmins (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichAdmins(funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichAdminBenutzer>>;
    public getFunktionsbereichAdmins(funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichAdminBenutzer>>>;
    public getFunktionsbereichAdmins(funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichAdminBenutzer>>>;
    public getFunktionsbereichAdmins(funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getFunktionsbereichAdmins.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichAdminBenutzer>>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/admins`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichDetail (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichDetail(funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<FunktionsbereichDetail>;
    public getFunktionsbereichDetail(funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FunktionsbereichDetail>>;
    public getFunktionsbereichDetail(funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FunktionsbereichDetail>>;
    public getFunktionsbereichDetail(funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getFunktionsbereichDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FunktionsbereichDetail>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichFreigabeRollen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichFreigabeRollen(funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichFreigabeRolle>>;
    public getFunktionsbereichFreigabeRollen(funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichFreigabeRolle>>>;
    public getFunktionsbereichFreigabeRollen(funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichFreigabeRolle>>>;
    public getFunktionsbereichFreigabeRollen(funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getFunktionsbereichFreigabeRollen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichFreigabeRolle>>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/freigaberollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichRollen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichRollen(funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Rolle>>;
    public getFunktionsbereichRollen(funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Rolle>>>;
    public getFunktionsbereichRollen(funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Rolle>>>;
    public getFunktionsbereichRollen(funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getFunktionsbereichRollen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Rolle>>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/rollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereiche (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereiche(observe?: 'body', reportProgress?: boolean): Observable<Array<Funktionsbereich>>;
    public getFunktionsbereiche(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Funktionsbereich>>>;
    public getFunktionsbereiche(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Funktionsbereich>>>;
    public getFunktionsbereiche(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Funktionsbereich>>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereicheRollen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereicheRollen(observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRollen>>;
    public getFunktionsbereicheRollen(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollen(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollen(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRollen>>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche/rollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereicheRollenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereicheRollenAdmin(observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRollen>>;
    public getFunktionsbereicheRollenAdmin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollenAdmin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollenAdmin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRollen>>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche/rollen/admin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereicheRollenAlle (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin oder AnyFunktionsbereichAdmin  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereicheRollenAlle(observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRollen>>;
    public getFunktionsbereicheRollenAlle(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollenAlle(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollenAlle(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRollen>>('get',`${this.basePath}/api/query/benutzerverwaltung/funktionsbereiche/rollen/alle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
