export namespace QsChecksEntityTypes {
    export const PRUEFLAUF_ENTITY = 'QS.PRUEFLAUF';
    export const PRUEFLAUF_ENTITY_PART_PRUEFLAUF_BILD = 'BILD';
    export const PRUEFLAUF_ENTITY_PART_PRUEFLAUF_BEMERKUNG = 'BEMERKUNG';
    export const PRUEFLAUF_ENTITY_PART_AUFGABE_BEMERKUNG = 'AUFGABE-BEMERKUNG';
    export const PRUEFLAUF_ENTITY_PART_AUFGABE_BILD = 'AUFGABE-BILD';
    export const PRUEFPLAN_ENTITY = 'QS.PPLAN';
    export const PRUEFPLAN_ENTITY_PART_PRUEFANTWORT = 'PA';
    export const PRUEFPLAN_ENTITY_PART_ROLLE_PRUEFER = 'ROLPRUEFER';
    export const PRUEFPLAN_ENTITY_PART_ROLLE_CONTROLLER = 'ROLCONTR';
    export const PRUEFPLAN_ENTITY_PART_PRUEFPLAN = 'PLAN';
    export const PRUEFPLAN_ENTITY_PART_PRUEFOBJEKT = 'POBJ';
    export const PRUEFPLAN_ENTITY_PART_PRUEFCHECKLISTE = 'CHKL';
    export const PRUEFPLAN_ENTITY_PART_PRUEFAUFGABE = 'AUFG';
    export const ROLLE_ENTITY = 'QS.ROLLE';
    }

