import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_ALLE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungenAlleAdmin laden';
export const GET_VERANSTALTUNGEN_ALLE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenAlleAdmin ausführen';
export const GET_VERANSTALTUNGEN_ALLE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenAlleAdmin laden erfolgreich';
export const GET_VERANSTALTUNGEN_ALLE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenAlleAdmin Ladefehler';
export const GET_VERANSTALTUNGEN_ALLE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenAlleAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenAlleAdminAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Veranstaltung>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenAlleAdminActions =
    GetVeranstaltungenAlleAdminAction
    | GetVeranstaltungenAlleAdminAusfuehrenAction
    | GetVeranstaltungenAlleAdminErfolgreichAction
    | GetVeranstaltungenAlleAdminFehlerAction
    | GetVeranstaltungenAlleAdminNichtAusgefuehrtAction
    ;
