export namespace IncidentsEntityTypes {
    export const FUNKTIONSBEREICH_ENTITY = 'INC.FB';
    export const INCIDENT_ENTITY = 'INC.INCIDENT';
    export const INCIDENT_ENTITY_PART_BILD = 'BILD';
    export const INCIDENT_ENTITY_PART_DETAIL = 'DETAIL';
    export const INCIDENT_ENTITY_PART_PINNWAND = 'PINNWAND';
    export const ROLLE_ENTITY = 'INC.ROLLE';
    export const ROLLE_ENTITY_PART_ABTEILUNG = 'ABTEILUNG';
    export const ROLLE_ENTITY_PART_BERECHTIGUNG = 'BERECHTIGUNG';
    export const ROLLE_ENTITY_PART_ZENTRALE = 'ZENTRALE';
    }

