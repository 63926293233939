/**
 * orga app | incidents
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DienstplanFunktionsbereich } from '../model/dienstplanFunktionsbereich';
import { FunktionsbereichAbteilung } from '../model/funktionsbereichAbteilung';
import { IncidentBerechtigung } from '../model/incidentBerechtigung';
import { IncidentBildsIds } from '../model/incidentBildsIds';
import { IncidentDetail } from '../model/incidentDetail';
import { IncidentKopf } from '../model/incidentKopf';
import { IncidentManagementBenutzerZugriff } from '../model/incidentManagementBenutzerZugriff';
import { IncidentsCounter } from '../model/incidentsCounter';
import { IncidentsListResponse } from '../model/incidentsListResponse';
import { IncidentsRolleBerechtigung } from '../model/incidentsRolleBerechtigung';
import { RolleAbteilung } from '../model/rolleAbteilung';
import { RolleZentrale } from '../model/rolleZentrale';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class IncidentsQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetFunktionsbereichAbteilung (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichAbteilung(funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<FunktionsbereichAbteilung>;
    public getFunktionsbereichAbteilung(funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FunktionsbereichAbteilung>>;
    public getFunktionsbereichAbteilung(funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FunktionsbereichAbteilung>>;
    public getFunktionsbereichAbteilung(funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getFunktionsbereichAbteilung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FunktionsbereichAbteilung>('get',`${this.basePath}/api/incidents/query/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/abteilung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentBerechtigung (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param incident_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentBerechtigung(incident_id: string, observe?: 'body', reportProgress?: boolean): Observable<IncidentBerechtigung>;
    public getIncidentBerechtigung(incident_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentBerechtigung>>;
    public getIncidentBerechtigung(incident_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentBerechtigung>>;
    public getIncidentBerechtigung(incident_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (incident_id === null || incident_id === undefined) {
            throw new Error('Required parameter incident_id was null or undefined when calling getIncidentBerechtigung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentBerechtigung>('get',`${this.basePath}/api/incidents/query/incident/${encodeURIComponent(String(incident_id))}/berechtigung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentBild (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param incident_id 
     * @param bild_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentBild(incident_id: string, bild_id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getIncidentBild(incident_id: string, bild_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getIncidentBild(incident_id: string, bild_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getIncidentBild(incident_id: string, bild_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (incident_id === null || incident_id === undefined) {
            throw new Error('Required parameter incident_id was null or undefined when calling getIncidentBild.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bild_id !== undefined && bild_id !== null) {
            queryParameters = queryParameters.set('bild_id', <any>bild_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/incidents/query/incident/${encodeURIComponent(String(incident_id))}/bild`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentBildIds (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param incident_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentBildIds(incident_id: string, observe?: 'body', reportProgress?: boolean): Observable<IncidentBildsIds>;
    public getIncidentBildIds(incident_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentBildsIds>>;
    public getIncidentBildIds(incident_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentBildsIds>>;
    public getIncidentBildIds(incident_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (incident_id === null || incident_id === undefined) {
            throw new Error('Required parameter incident_id was null or undefined when calling getIncidentBildIds.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentBildsIds>('get',`${this.basePath}/api/incidents/query/incident/${encodeURIComponent(String(incident_id))}/bilds_ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentBildScaled (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param incident_id 
     * @param bild_id 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentBildScaled(incident_id: string, bild_id?: string, width?: number, height?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getIncidentBildScaled(incident_id: string, bild_id?: string, width?: number, height?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getIncidentBildScaled(incident_id: string, bild_id?: string, width?: number, height?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getIncidentBildScaled(incident_id: string, bild_id?: string, width?: number, height?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (incident_id === null || incident_id === undefined) {
            throw new Error('Required parameter incident_id was null or undefined when calling getIncidentBildScaled.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bild_id !== undefined && bild_id !== null) {
            queryParameters = queryParameters.set('bild_id', <any>bild_id);
        }
        if (width !== undefined && width !== null) {
            queryParameters = queryParameters.set('width', <any>width);
        }
        if (height !== undefined && height !== null) {
            queryParameters = queryParameters.set('height', <any>height);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/incidents/query/incident/${encodeURIComponent(String(incident_id))}/bild/scaled`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentByFunkprotokollFall (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param veranstaltung_id 
     * @param fall_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentByFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe?: 'body', reportProgress?: boolean): Observable<IncidentKopf>;
    public getIncidentByFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentKopf>>;
    public getIncidentByFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentKopf>>;
    public getIncidentByFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getIncidentByFunkprotokollFall.');
        }

        if (fall_id === null || fall_id === undefined) {
            throw new Error('Required parameter fall_id was null or undefined when calling getIncidentByFunkprotokollFall.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentKopf>('get',`${this.basePath}/api/incidents/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/funkprotokoll/${encodeURIComponent(String(fall_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentDetail (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param incident_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentDetail(incident_id: string, observe?: 'body', reportProgress?: boolean): Observable<IncidentDetail>;
    public getIncidentDetail(incident_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentDetail>>;
    public getIncidentDetail(incident_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentDetail>>;
    public getIncidentDetail(incident_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (incident_id === null || incident_id === undefined) {
            throw new Error('Required parameter incident_id was null or undefined when calling getIncidentDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentDetail>('get',`${this.basePath}/api/incidents/query/incident/${encodeURIComponent(String(incident_id))}/detail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentsBenutzerZugriff (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentsBenutzerZugriff(observe?: 'body', reportProgress?: boolean): Observable<IncidentManagementBenutzerZugriff>;
    public getIncidentsBenutzerZugriff(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentManagementBenutzerZugriff>>;
    public getIncidentsBenutzerZugriff(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentManagementBenutzerZugriff>>;
    public getIncidentsBenutzerZugriff(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentManagementBenutzerZugriff>('get',`${this.basePath}/api/incidents/query/zugriff`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentsRolleBerechtigung (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param rolle_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentsRolleBerechtigung(rolle_id: string, observe?: 'body', reportProgress?: boolean): Observable<IncidentsRolleBerechtigung>;
    public getIncidentsRolleBerechtigung(rolle_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentsRolleBerechtigung>>;
    public getIncidentsRolleBerechtigung(rolle_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentsRolleBerechtigung>>;
    public getIncidentsRolleBerechtigung(rolle_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (rolle_id === null || rolle_id === undefined) {
            throw new Error('Required parameter rolle_id was null or undefined when calling getIncidentsRolleBerechtigung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentsRolleBerechtigung>('get',`${this.basePath}/api/incidents/query/rollen/${encodeURIComponent(String(rolle_id))}/berechtigung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentsVeranstaltungCounter (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentsVeranstaltungCounter(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<IncidentsCounter>;
    public getIncidentsVeranstaltungCounter(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentsCounter>>;
    public getIncidentsVeranstaltungCounter(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentsCounter>>;
    public getIncidentsVeranstaltungCounter(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getIncidentsVeranstaltungCounter.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentsCounter>('get',`${this.basePath}/api/incidents/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/counter`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMeineIncidentsVeranstaltung (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeineIncidentsVeranstaltung(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<IncidentsListResponse>;
    public getMeineIncidentsVeranstaltung(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentsListResponse>>;
    public getMeineIncidentsVeranstaltung(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentsListResponse>>;
    public getMeineIncidentsVeranstaltung(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getMeineIncidentsVeranstaltung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentsListResponse>('get',`${this.basePath}/api/incidents/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/meine`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetRolleAbteilung (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param rolle_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolleAbteilung(rolle_id: string, observe?: 'body', reportProgress?: boolean): Observable<RolleAbteilung>;
    public getRolleAbteilung(rolle_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RolleAbteilung>>;
    public getRolleAbteilung(rolle_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RolleAbteilung>>;
    public getRolleAbteilung(rolle_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (rolle_id === null || rolle_id === undefined) {
            throw new Error('Required parameter rolle_id was null or undefined when calling getRolleAbteilung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RolleAbteilung>('get',`${this.basePath}/api/incidents/query/rollen/${encodeURIComponent(String(rolle_id))}/abteilung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetRolleZentrale (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Lizenz: incidents  
     * @param rolle_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolleZentrale(rolle_id: string, observe?: 'body', reportProgress?: boolean): Observable<RolleZentrale>;
    public getRolleZentrale(rolle_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RolleZentrale>>;
    public getRolleZentrale(rolle_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RolleZentrale>>;
    public getRolleZentrale(rolle_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (rolle_id === null || rolle_id === undefined) {
            throw new Error('Required parameter rolle_id was null or undefined when calling getRolleZentrale.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RolleZentrale>('get',`${this.basePath}/api/incidents/query/rollen/${encodeURIComponent(String(rolle_id))}/zentrale`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDienstplan (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DienstplanFunktionsbereich>>;
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DienstplanFunktionsbereich>>>;
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DienstplanFunktionsbereich>>>;
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDienstplan.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DienstplanFunktionsbereich>>('get',`${this.basePath}/api/incidents/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/dienstplan`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
