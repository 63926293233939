import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BildInfo} from './bildInfo';
import {BehaviorSubject, Observable} from 'rxjs';
import {IonSlides, ModalController, NavParams} from '@ionic/angular';


@Component({
    selector: 'bild-vorschau',
    templateUrl: './bild-vorschau.html'
})
export class BildVorschauComponent implements OnInit, OnDestroy {
    @ViewChild('slides', {static: false}) slides: IonSlides;
    bilder$: Observable<BildInfo[]>;
    // initialSlide$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public slidesOpts: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public slidesOpts$: Observable<any>;

    titel: string = this.params.get('titel');

    constructor(private viewCtrl: ModalController, public params: NavParams, public domSanitizer: DomSanitizer) {
        this.slidesOpts$ = this.slidesOpts.asObservable();

    }

    ngOnInit(): void {

    }

    ionViewWillEnter() {
        const bilder = this.params.get('bilder');
        const initialImage = this.params.get('initialImage');
        if (bilder !== undefined) {
            this.bilder$ = bilder;
        }

        if (initialImage != null) {
            // this.initialSlide$.next(initialImage);
            this.setSlideOps(initialImage);
        }
    }


    ngOnDestroy(): void {
    }

    closeModal(): void {
        this.viewCtrl.dismiss();
    }

    async setSlideOps(initialSlide: number) {
        const ops = {initialSlide};
        await this.slidesOpts.next(ops);
    }

    async slideChanged() {
        // // let currentIndex = this.slides.realIndex;
        // const currentIndex = await this.slides.getActiveIndex();
        // if (currentIndex !== undefined) {
        //     this.initialSlide$.next(currentIndex);
        // }
    }

}
