import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFBERICHT_DATA = '[VeranstaltungenModul] GetVeranstaltungPruefberichtData laden';
export const GET_VERANSTALTUNG_PRUEFBERICHT_DATA_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungPruefberichtData ausführen';
export const GET_VERANSTALTUNG_PRUEFBERICHT_DATA_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungPruefberichtData laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFBERICHT_DATA_FEHLER = '[VeranstaltungenModul] GetVeranstaltungPruefberichtData Ladefehler';
export const GET_VERANSTALTUNG_PRUEFBERICHT_DATA_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungPruefberichtData nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPruefberichtDataAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_DATA;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtDataAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_DATA_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtDataErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_DATA_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungPruefbericht,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtDataFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_DATA_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtDataNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_DATA_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungPruefberichtDataActions =
    GetVeranstaltungPruefberichtDataAction
    | GetVeranstaltungPruefberichtDataAusfuehrenAction
    | GetVeranstaltungPruefberichtDataErfolgreichAction
    | GetVeranstaltungPruefberichtDataFehlerAction
    | GetVeranstaltungPruefberichtDataNichtAusgefuehrtAction
    ;
