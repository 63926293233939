/**
 * orga app | vorlagen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Dokument } from '../model/dokument';
import { DokumentBerechtigung } from '../model/dokumentBerechtigung';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DokumenteQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetDokument (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param dokument_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDokument(dokument_id: string, observe?: 'body', reportProgress?: boolean): Observable<Dokument>;
    public getDokument(dokument_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Dokument>>;
    public getDokument(dokument_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Dokument>>;
    public getDokument(dokument_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dokument_id === null || dokument_id === undefined) {
            throw new Error('Required parameter dokument_id was null or undefined when calling getDokument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Dokument>('get',`${this.basePath}/api/query/vorlagen/dokumente/${encodeURIComponent(String(dokument_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetDokumentBerechtigung (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param dokument_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDokumentBerechtigung(dokument_id: string, observe?: 'body', reportProgress?: boolean): Observable<DokumentBerechtigung>;
    public getDokumentBerechtigung(dokument_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DokumentBerechtigung>>;
    public getDokumentBerechtigung(dokument_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DokumentBerechtigung>>;
    public getDokumentBerechtigung(dokument_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dokument_id === null || dokument_id === undefined) {
            throw new Error('Required parameter dokument_id was null or undefined when calling getDokumentBerechtigung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DokumentBerechtigung>('get',`${this.basePath}/api/query/vorlagen/dokumente/${encodeURIComponent(String(dokument_id))}/berechtigung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetDokumentDownload (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param dokument_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDokumentDownload(dokument_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getDokumentDownload(dokument_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getDokumentDownload(dokument_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getDokumentDownload(dokument_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dokument_id === null || dokument_id === undefined) {
            throw new Error('Required parameter dokument_id was null or undefined when calling getDokumentDownload.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/vorlagen/dokumente/${encodeURIComponent(String(dokument_id))}/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetDokumente (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDokumente(observe?: 'body', reportProgress?: boolean): Observable<Array<Dokument>>;
    public getDokumente(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Dokument>>>;
    public getDokumente(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Dokument>>>;
    public getDokumente(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Dokument>>('get',`${this.basePath}/api/query/vorlagen/dokumente`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
