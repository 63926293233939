export namespace VorlagenEntityTypes {
    export const AUFGABE_ENTITY = 'VORL.AUFG';
    export const AUFGABE_ENTITY_PART_BILD = 'BILD';
    export const AUFGABE_ENTITY_PART_DETAIL = 'DETAIL';
    export const AUFGABE_ENTITY_PART_REIHENFOLGE_FERTIG = 'REIHENFOLGE_FERTIG';
    export const AUFGABE_ENTITY_PART_BENUTZER_BERECHTIGUNG = 'BENUTZER_BERECHTIGUNG';
    export const CHECKLISTE_ENTITY = 'VORL.CHECKL';
    export const DOKUMENT_ENTITY = 'VORL.DOK';
    }

