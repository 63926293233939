

import {Action} from '@ngrx/store';

export const POST_MANDANT_CONFIG_UPLOAD = '[ApiModul] PostMandantConfigUpload laden';
export const POST_MANDANT_CONFIG_UPLOAD_AUSFUEHREN = '[ApiModul] PostMandantConfigUpload ausführen';
export const POST_MANDANT_CONFIG_UPLOAD_ERFOLGREICH = '[ApiModul] PostMandantConfigUpload laden erfolgreich';
export const POST_MANDANT_CONFIG_UPLOAD_FEHLER = '[ApiModul] PostMandantConfigUpload Ladefehler';
export const POST_MANDANT_CONFIG_UPLOAD_NICHT_AUSGEFUEHRT = '[ApiModul] PostMandantConfigUpload nicht ausgeführt (evtl. Offline)';



export class PostMandantConfigUploadAction implements Action {
    readonly type = POST_MANDANT_CONFIG_UPLOAD;
        constructor( public file:Blob , public optPayload: any = null) {}
    }
export class PostMandantConfigUploadAusfuehrenAction implements Action {
    readonly type = POST_MANDANT_CONFIG_UPLOAD_AUSFUEHREN;
        constructor( public file:Blob , public optPayload: any = null) {}
    }
export class PostMandantConfigUploadErfolgreichAction implements Action {
    readonly type = POST_MANDANT_CONFIG_UPLOAD_ERFOLGREICH;
        constructor(public payload: string,  public file:Blob , public optPayload: any = null) {}
    }
export class PostMandantConfigUploadFehlerAction implements Action {
    readonly type = POST_MANDANT_CONFIG_UPLOAD_FEHLER;
        constructor(public payload: any,  public file:Blob , public optPayload: any = null) {}
    }
export class PostMandantConfigUploadNichtAusgefuehrtAction implements Action {
    readonly type = POST_MANDANT_CONFIG_UPLOAD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiCommandMandantConfigUploadActions =
    PostMandantConfigUploadAction
    | PostMandantConfigUploadAusfuehrenAction
    | PostMandantConfigUploadErfolgreichAction
    | PostMandantConfigUploadFehlerAction
    | PostMandantConfigUploadNichtAusgefuehrtAction
    ;
