import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtData laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtData ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtData laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtData Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtData nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichPruefberichtDataAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtDataAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtDataErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungPruefbericht,  public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtDataFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtDataNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_DATA_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichPruefberichtDataActions =
    GetVeranstaltungFunktionsbereichPruefberichtDataAction
    | GetVeranstaltungFunktionsbereichPruefberichtDataAusfuehrenAction
    | GetVeranstaltungFunktionsbereichPruefberichtDataErfolgreichAction
    | GetVeranstaltungFunktionsbereichPruefberichtDataFehlerAction
    | GetVeranstaltungFunktionsbereichPruefberichtDataNichtAusgefuehrtAction
    ;
