import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-inner-item',
    templateUrl: './sopi-inner-item.component.html',
    styleUrls: ['./sopi-inner-item.component.scss'],
})
export class SopiInnerItemComponent{

    // Margin-Handling
    @Input() counterActive = false;

    // Obere Headerzeile
    @Input() rowTopEnabled = true;
    @Input() topLeftEnabled = true;
    @Input() topLeftValue: string = null;
    @Input() topRightEnabled = true;
    @Input() topRightValue: string = null;
    @Input() showOnlyReadingHint = false;
    @Input() writingRights = false;


    // Mittlerer Hauptbereich
    @Input() mainValue: string = null;
    @Input() errorImages: Array<string> = null;
    @Input() images: Array<string> = null;
    @Input() rowSubEnabled = true;
    @Input() subValue: string = null;
    @Input() iconIstOk = false;
    @Input() iconIstNichtOk = false;
    @Input() iconIstFreigegeben = false;
    @Input() iconIstInBearbeitung = false;
    @Input() badgeEnabled = true;
    @Input() badgeValue: string = null;
    @Input() badgeStyleClass: string = null;

    // 1. Sub-Zeile (Berkungsfeld)
    @Input() rowBottomSubEnabled = true;
    @Input() bottomIconEnabled = true;
    @Input() bottomIconName: string = null;
    @Input() bottomLeftEnabled = true;
    @Input() bottomLeftValue: string = null;
    @Input() bottomRightEnabled = true;
    @Input() bottomRightValue: string = null;
    @Input() bottomSubValue: string = null;

    // 2. Sub-Zeile (Berkungsfeld)
    @Input() rowBottom2SubEnabled = true;
    @Input() bottom2IconEnabled = true;
    @Input() bottom2IconName: string = null;
    @Input() bottom2LeftEnabled = true;
    @Input() bottom2LeftValue: string = null;
    @Input() bottom2RightEnabled = true;
    @Input() bottom2RightValue: string = null;
    @Input() bottom2SubValue: string = null;

    // 3. Sub-Zeile (Berkungsfeld)
    @Input() rowBottom3SubEnabled = true;
    @Input() bottom3IconEnabled = true;
    @Input() bottom3IconName: string = null;
    @Input() bottom3LeftEnabled = true;
    @Input() bottom3LeftValue: string = null;
    @Input() bottom3RightEnabled = true;
    @Input() bottom3RightValue: string = null;
    @Input() bottom3SubValue: string = null;

    // Standort
    @Input() showLocation = false;
    @Input() locationValue: string = null;

}