/**
 * orga app | veranstaltungen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DokumentAktualisierenRequest } from '../model/dokumentAktualisierenRequest';
import { DokumentBerechtigungMultiRequest } from '../model/dokumentBerechtigungMultiRequest';
import { DokumentBerechtigungRequest } from '../model/dokumentBerechtigungRequest';
import { DokumentEntfernenRequest } from '../model/dokumentEntfernenRequest';
import { DokumentHinzufuegenRequest } from '../model/dokumentHinzufuegenRequest';
import { DokumentUploadAktualisierenRequest } from '../model/dokumentUploadAktualisierenRequest';
import { MessageResponse } from '../model/messageResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DokumenteCommandService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostDokumentAktualisieren (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentAktualisieren(body?: DokumentAktualisierenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentAktualisieren(body?: DokumentAktualisierenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentAktualisieren(body?: DokumentAktualisierenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentAktualisieren(body?: DokumentAktualisierenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBearbeitenBerechtigungEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBearbeitenBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBearbeitenBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/bearbeiten/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBearbeitenBerechtigungEntfernenMulti (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBearbeitenBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBearbeitenBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/bearbeiten/entfernen/multi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBearbeitenBerechtigungHinzufuegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBearbeitenBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBearbeitenBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/bearbeiten/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBearbeitenBerechtigungHinzufuegenMulti (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBearbeitenBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBearbeitenBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBearbeitenBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/bearbeiten/hinzufuegen/multi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBerechtigungEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBerechtigungEntfernen(body?: DokumentBerechtigungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBerechtigungEntfernenMulti (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBerechtigungEntfernenMulti(body?: DokumentBerechtigungMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/entfernen/multi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBerechtigungHinzufuegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBerechtigungHinzufuegen(body?: DokumentBerechtigungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentBerechtigungHinzufuegenMulti (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentBerechtigungHinzufuegenMulti(body?: DokumentBerechtigungMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/berechtigung/hinzufuegen/multi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentEntfernen(body?: DokumentEntfernenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentEntfernen(body?: DokumentEntfernenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentEntfernen(body?: DokumentEntfernenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentEntfernen(body?: DokumentEntfernenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentHinzufuegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentHinzufuegen(body?: DokumentHinzufuegenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentHinzufuegen(body?: DokumentHinzufuegenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentHinzufuegen(body?: DokumentHinzufuegenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentHinzufuegen(body?: DokumentHinzufuegenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostDokumentUploadAktualisieren (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDokumentUploadAktualisieren(body?: DokumentUploadAktualisierenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postDokumentUploadAktualisieren(body?: DokumentUploadAktualisierenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postDokumentUploadAktualisieren(body?: DokumentUploadAktualisierenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postDokumentUploadAktualisieren(body?: DokumentUploadAktualisierenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/dokumente/uploadaktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
