/**
 * orga app | veranstaltungstypen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Funktionsbereich } from '../model/funktionsbereich';
import { FunktionsbereichRolle } from '../model/funktionsbereichRolle';
import { RolleBenutzer } from '../model/rolleBenutzer';
import { VeranstaltungsTyp } from '../model/veranstaltungsTyp';
import { VeranstaltungsTypDetail } from '../model/veranstaltungsTypDetail';
import { VeranstaltungsTypFeld } from '../model/veranstaltungsTypFeld';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VeranstaltungsTypenQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetVeranstaltungsTypAdminRollen (Auth)
     * ORGA App Zugriff:  * Mandant: Admin  
     * @param veranstaltungstyp_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypAdminRollen(veranstaltungstyp_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRolle>>;
    public getVeranstaltungsTypAdminRollen(veranstaltungstyp_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRolle>>>;
    public getVeranstaltungsTypAdminRollen(veranstaltungstyp_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRolle>>>;
    public getVeranstaltungsTypAdminRollen(veranstaltungstyp_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltungstyp_id === null || veranstaltungstyp_id === undefined) {
            throw new Error('Required parameter veranstaltungstyp_id was null or undefined when calling getVeranstaltungsTypAdminRollen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRolle>>('get',`${this.basePath}/api/query/veranstaltungstypen/${encodeURIComponent(String(veranstaltungstyp_id))}/rollen/admin`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungsTypDetail (Auth)
     * ORGA App Zugriff:  * Mandant: Admin  
     * @param veranstaltungstyp_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypDetail(veranstaltungstyp_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungsTypDetail>;
    public getVeranstaltungsTypDetail(veranstaltungstyp_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungsTypDetail>>;
    public getVeranstaltungsTypDetail(veranstaltungstyp_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungsTypDetail>>;
    public getVeranstaltungsTypDetail(veranstaltungstyp_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltungstyp_id === null || veranstaltungstyp_id === undefined) {
            throw new Error('Required parameter veranstaltungstyp_id was null or undefined when calling getVeranstaltungsTypDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungsTypDetail>('get',`${this.basePath}/api/query/veranstaltungstypen/${encodeURIComponent(String(veranstaltungstyp_id))}/detail`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungsTypFelder (Auth)
     * ORGA App Zugriff:  * Mandant: Admin  
     * @param veranstaltungstyp_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypFelder(veranstaltungstyp_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VeranstaltungsTypFeld>>;
    public getVeranstaltungsTypFelder(veranstaltungstyp_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VeranstaltungsTypFeld>>>;
    public getVeranstaltungsTypFelder(veranstaltungstyp_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VeranstaltungsTypFeld>>>;
    public getVeranstaltungsTypFelder(veranstaltungstyp_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltungstyp_id === null || veranstaltungstyp_id === undefined) {
            throw new Error('Required parameter veranstaltungstyp_id was null or undefined when calling getVeranstaltungsTypFelder.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VeranstaltungsTypFeld>>('get',`${this.basePath}/api/query/veranstaltungstypen/${encodeURIComponent(String(veranstaltungstyp_id))}/felder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungsTypFunktionsbereiche (Auth)
     * 
     * @param veranstaltungstyp_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypFunktionsbereiche(veranstaltungstyp_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Funktionsbereich>>;
    public getVeranstaltungsTypFunktionsbereiche(veranstaltungstyp_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Funktionsbereich>>>;
    public getVeranstaltungsTypFunktionsbereiche(veranstaltungstyp_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Funktionsbereich>>>;
    public getVeranstaltungsTypFunktionsbereiche(veranstaltungstyp_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltungstyp_id === null || veranstaltungstyp_id === undefined) {
            throw new Error('Required parameter veranstaltungstyp_id was null or undefined when calling getVeranstaltungsTypFunktionsbereiche.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Funktionsbereich>>('get',`${this.basePath}/api/query/veranstaltungstypen/${encodeURIComponent(String(veranstaltungstyp_id))}/funktionsbereiche`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungsTypRolleBenutzer (Auth)
     * 
     * @param veranstaltungstyp_id 
     * @param rolle_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypRolleBenutzer(veranstaltungstyp_id: string, rolle_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RolleBenutzer>>;
    public getVeranstaltungsTypRolleBenutzer(veranstaltungstyp_id: string, rolle_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RolleBenutzer>>>;
    public getVeranstaltungsTypRolleBenutzer(veranstaltungstyp_id: string, rolle_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RolleBenutzer>>>;
    public getVeranstaltungsTypRolleBenutzer(veranstaltungstyp_id: string, rolle_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltungstyp_id === null || veranstaltungstyp_id === undefined) {
            throw new Error('Required parameter veranstaltungstyp_id was null or undefined when calling getVeranstaltungsTypRolleBenutzer.');
        }

        if (rolle_id === null || rolle_id === undefined) {
            throw new Error('Required parameter rolle_id was null or undefined when calling getVeranstaltungsTypRolleBenutzer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RolleBenutzer>>('get',`${this.basePath}/api/query/veranstaltungstypen/${encodeURIComponent(String(veranstaltungstyp_id))}/${encodeURIComponent(String(rolle_id))}/benutzer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungsTypen (Auth)
     * ORGA App Zugriff:  * Mandant: Admin  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypen(observe?: 'body', reportProgress?: boolean): Observable<Array<VeranstaltungsTyp>>;
    public getVeranstaltungsTypen(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VeranstaltungsTyp>>>;
    public getVeranstaltungsTypen(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VeranstaltungsTyp>>>;
    public getVeranstaltungsTypen(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VeranstaltungsTyp>>('get',`${this.basePath}/api/query/veranstaltungstypen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungsTypenAktiv (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypenAktiv(observe?: 'body', reportProgress?: boolean): Observable<Array<VeranstaltungsTyp>>;
    public getVeranstaltungsTypenAktiv(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VeranstaltungsTyp>>>;
    public getVeranstaltungsTypenAktiv(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VeranstaltungsTyp>>>;
    public getVeranstaltungsTypenAktiv(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VeranstaltungsTyp>>('get',`${this.basePath}/api/query/veranstaltungstypen/aktiv`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
