/**
 * orga app | benutzerverwaltung
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FunktionsbereichAnlegenRequest } from '../model/funktionsbereichAnlegenRequest';
import { FunktionsbereichBenutzerRequest } from '../model/funktionsbereichBenutzerRequest';
import { FunktionsbereichEmpfaengerRequest } from '../model/funktionsbereichEmpfaengerRequest';
import { FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest } from '../model/funktionsbereichExpliziteFreigabeErforderlichFestlegenRequest';
import { FunktionsbereichRequest } from '../model/funktionsbereichRequest';
import { FunktionsbereichUmbenennenRequest } from '../model/funktionsbereichUmbenennenRequest';
import { MessageResponse } from '../model/messageResponse';
import { RolleRequest } from '../model/rolleRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FunktionsbereicheCommandService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostFunktionsbereichAdminEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichAdminEntfernen(body?: FunktionsbereichBenutzerRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichAdminEntfernen(body?: FunktionsbereichBenutzerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichAdminEntfernen(body?: FunktionsbereichBenutzerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichAdminEntfernen(body?: FunktionsbereichBenutzerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/admin/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichAdminHinzufuegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichAdminHinzufuegen(body?: FunktionsbereichBenutzerRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichAdminHinzufuegen(body?: FunktionsbereichBenutzerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichAdminHinzufuegen(body?: FunktionsbereichBenutzerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichAdminHinzufuegen(body?: FunktionsbereichBenutzerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/admin/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichAnlegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichAnlegen(body?: FunktionsbereichAnlegenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichAnlegen(body?: FunktionsbereichAnlegenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichAnlegen(body?: FunktionsbereichAnlegenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichAnlegen(body?: FunktionsbereichAnlegenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/anlegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichEMailBenachrichtigungBereitschaft (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichEMailBenachrichtigungBereitschaft(body?: FunktionsbereichEmpfaengerRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichEMailBenachrichtigungBereitschaft(body?: FunktionsbereichEmpfaengerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichEMailBenachrichtigungBereitschaft(body?: FunktionsbereichEmpfaengerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichEMailBenachrichtigungBereitschaft(body?: FunktionsbereichEmpfaengerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/benachrichtigung/bereitschaft/email`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichEMailBenachrichtigungFehlerfall (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichEMailBenachrichtigungFehlerfall(body?: FunktionsbereichEmpfaengerRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichEMailBenachrichtigungFehlerfall(body?: FunktionsbereichEmpfaengerRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichEMailBenachrichtigungFehlerfall(body?: FunktionsbereichEmpfaengerRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichEMailBenachrichtigungFehlerfall(body?: FunktionsbereichEmpfaengerRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/benachrichtigung/fehlerfall/email`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichExpliziteFreigabeFestlegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichExpliziteFreigabeFestlegen(body?: FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichExpliziteFreigabeFestlegen(body?: FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichExpliziteFreigabeFestlegen(body?: FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichExpliziteFreigabeFestlegen(body?: FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/explizitefreigabe`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichFreigabeBerechtigungRolleEntfernen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichFreigabeBerechtigungRolleEntfernen(body?: RolleRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichFreigabeBerechtigungRolleEntfernen(body?: RolleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichFreigabeBerechtigungRolleEntfernen(body?: RolleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichFreigabeBerechtigungRolleEntfernen(body?: RolleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/freigabeberechtigung/rolle/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichFreigabeBerechtigungRolleHinzufuegen(body?: RolleRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichFreigabeBerechtigungRolleHinzufuegen(body?: RolleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichFreigabeBerechtigungRolleHinzufuegen(body?: RolleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichFreigabeBerechtigungRolleHinzufuegen(body?: RolleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/freigabeberechtigung/rolle/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichLoeschen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichLoeschen(body?: FunktionsbereichRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichLoeschen(body?: FunktionsbereichRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichLoeschen(body?: FunktionsbereichRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichLoeschen(body?: FunktionsbereichRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/loeschen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostFunktionsbereichUmbenennen (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  * Mandant: IsAdmin oder Funktionsbereichs Admin  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichUmbenennen(body?: FunktionsbereichUmbenennenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichUmbenennen(body?: FunktionsbereichUmbenennenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichUmbenennen(body?: FunktionsbereichUmbenennenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichUmbenennen(body?: FunktionsbereichUmbenennenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/benutzerverwaltung/funktionsbereiche/umbenennen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
