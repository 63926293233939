export * from './aufgabenCommand.service';
import { AufgabenCommandService } from './aufgabenCommand.service';
export * from './checklisteCommand.service';
import { ChecklisteCommandService } from './checklisteCommand.service';
export * from './checklisteQuery.service';
import { ChecklisteQueryService } from './checklisteQuery.service';
export * from './dokumenteCommand.service';
import { DokumenteCommandService } from './dokumenteCommand.service';
export * from './dokumenteQuery.service';
import { DokumenteQueryService } from './dokumenteQuery.service';
export * from './vorlagenQuery.service';
import { VorlagenQueryService } from './vorlagenQuery.service';
export * from './vorlagenTemplates.service';
import { VorlagenTemplatesService } from './vorlagenTemplates.service';
export const APIS = [AufgabenCommandService, ChecklisteCommandService, ChecklisteQueryService, DokumenteCommandService, DokumenteQueryService, VorlagenQueryService, VorlagenTemplatesService];
