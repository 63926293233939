/**
 * orga app | qschecks
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PrueflaufAufgabeBemerkung } from '../model/prueflaufAufgabeBemerkung';
import { PrueflaufBemerkung } from '../model/prueflaufBemerkung';
import { PrueflaufBerechtigung } from '../model/prueflaufBerechtigung';
import { PrueflaufDetail } from '../model/prueflaufDetail';
import { PrueflaufRequest } from '../model/prueflaufRequest';
import { PrueflaufStatusRequest } from '../model/prueflaufStatusRequest';
import { VeranstaltungPrueflaufStatus } from '../model/veranstaltungPrueflaufStatus';
import { VeranstaltungPruefplanRollenListe } from '../model/veranstaltungPruefplanRollenListe';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PrueflaufQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetPrueflaufAufgabeBemerkung (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param prueflauf_id 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrueflaufAufgabeBemerkung(prueflauf_id: string, aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<PrueflaufAufgabeBemerkung>;
    public getPrueflaufAufgabeBemerkung(prueflauf_id: string, aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrueflaufAufgabeBemerkung>>;
    public getPrueflaufAufgabeBemerkung(prueflauf_id: string, aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrueflaufAufgabeBemerkung>>;
    public getPrueflaufAufgabeBemerkung(prueflauf_id: string, aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prueflauf_id === null || prueflauf_id === undefined) {
            throw new Error('Required parameter prueflauf_id was null or undefined when calling getPrueflaufAufgabeBemerkung.');
        }

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getPrueflaufAufgabeBemerkung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PrueflaufAufgabeBemerkung>('get',`${this.basePath}/api/qschecks/query/prueflauf/${encodeURIComponent(String(prueflauf_id))}/aufgaben/${encodeURIComponent(String(aufgabe_id))}/bemerkung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPrueflaufAufgabeBild (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param prueflauf_id 
     * @param aufgabe_id 
     * @param bild_id 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrueflaufAufgabeBild(prueflauf_id: string, aufgabe_id: string, bild_id?: string, width?: number, height?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPrueflaufAufgabeBild(prueflauf_id: string, aufgabe_id: string, bild_id?: string, width?: number, height?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPrueflaufAufgabeBild(prueflauf_id: string, aufgabe_id: string, bild_id?: string, width?: number, height?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPrueflaufAufgabeBild(prueflauf_id: string, aufgabe_id: string, bild_id?: string, width?: number, height?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prueflauf_id === null || prueflauf_id === undefined) {
            throw new Error('Required parameter prueflauf_id was null or undefined when calling getPrueflaufAufgabeBild.');
        }

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getPrueflaufAufgabeBild.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bild_id !== undefined && bild_id !== null) {
            queryParameters = queryParameters.set('bild_id', <any>bild_id);
        }
        if (width !== undefined && width !== null) {
            queryParameters = queryParameters.set('width', <any>width);
        }
        if (height !== undefined && height !== null) {
            queryParameters = queryParameters.set('height', <any>height);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/qschecks/query/prueflauf/${encodeURIComponent(String(prueflauf_id))}/aufgaben/${encodeURIComponent(String(aufgabe_id))}/bild`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPrueflaufBemerkung (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param prueflauf_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrueflaufBemerkung(prueflauf_id: string, observe?: 'body', reportProgress?: boolean): Observable<PrueflaufBemerkung>;
    public getPrueflaufBemerkung(prueflauf_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrueflaufBemerkung>>;
    public getPrueflaufBemerkung(prueflauf_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrueflaufBemerkung>>;
    public getPrueflaufBemerkung(prueflauf_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prueflauf_id === null || prueflauf_id === undefined) {
            throw new Error('Required parameter prueflauf_id was null or undefined when calling getPrueflaufBemerkung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PrueflaufBemerkung>('get',`${this.basePath}/api/qschecks/query/prueflauf/${encodeURIComponent(String(prueflauf_id))}/bemerkung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPrueflaufBerechtigung (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrueflaufBerechtigung(body?: PrueflaufStatusRequest, observe?: 'body', reportProgress?: boolean): Observable<PrueflaufBerechtigung>;
    public getPrueflaufBerechtigung(body?: PrueflaufStatusRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrueflaufBerechtigung>>;
    public getPrueflaufBerechtigung(body?: PrueflaufStatusRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrueflaufBerechtigung>>;
    public getPrueflaufBerechtigung(body?: PrueflaufStatusRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PrueflaufBerechtigung>('post',`${this.basePath}/api/qschecks/query/prueflauf/berechtigung/prueflauf`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPrueflaufBild (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param prueflauf_id 
     * @param bild_id 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPrueflaufBild(prueflauf_id: string, bild_id?: string, width?: number, height?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPrueflaufBild(prueflauf_id: string, bild_id?: string, width?: number, height?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPrueflaufBild(prueflauf_id: string, bild_id?: string, width?: number, height?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPrueflaufBild(prueflauf_id: string, bild_id?: string, width?: number, height?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (prueflauf_id === null || prueflauf_id === undefined) {
            throw new Error('Required parameter prueflauf_id was null or undefined when calling getPrueflaufBild.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (bild_id !== undefined && bild_id !== null) {
            queryParameters = queryParameters.set('bild_id', <any>bild_id);
        }
        if (width !== undefined && width !== null) {
            queryParameters = queryParameters.set('width', <any>width);
        }
        if (height !== undefined && height !== null) {
            queryParameters = queryParameters.set('height', <any>height);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/qschecks/query/prueflauf/${encodeURIComponent(String(prueflauf_id))}/bild`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPrueflauf (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPrueflauf(body?: PrueflaufRequest, observe?: 'body', reportProgress?: boolean): Observable<PrueflaufDetail>;
    public getVeranstaltungPrueflauf(body?: PrueflaufRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PrueflaufDetail>>;
    public getVeranstaltungPrueflauf(body?: PrueflaufRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PrueflaufDetail>>;
    public getVeranstaltungPrueflauf(body?: PrueflaufRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PrueflaufDetail>('post',`${this.basePath}/api/qschecks/query/prueflauf/veranstaltungen/prueflauf`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPrueflaufStatus (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPrueflaufStatus(body?: PrueflaufStatusRequest, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungPrueflaufStatus>;
    public getVeranstaltungPrueflaufStatus(body?: PrueflaufStatusRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungPrueflaufStatus>>;
    public getVeranstaltungPrueflaufStatus(body?: PrueflaufStatusRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungPrueflaufStatus>>;
    public getVeranstaltungPrueflaufStatus(body?: PrueflaufStatusRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VeranstaltungPrueflaufStatus>('post',`${this.basePath}/api/qschecks/query/prueflauf/veranstaltung/prueflaufstatus`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPruefplanRollenListe (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Pruefer | Controller  * Mandant: ValidUser  * Lizenz: qschecks  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPruefplanRollenListe(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungPruefplanRollenListe>;
    public getVeranstaltungPruefplanRollenListe(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungPruefplanRollenListe>>;
    public getVeranstaltungPruefplanRollenListe(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungPruefplanRollenListe>>;
    public getVeranstaltungPruefplanRollenListe(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungPruefplanRollenListe.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungPruefplanRollenListe>('get',`${this.basePath}/api/qschecks/query/prueflauf/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefplanrollenliste`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
