/**
 * orga app | qschecks
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MessageResponse } from '../model/messageResponse';
import { PruefAntwortModelRequest } from '../model/pruefAntwortModelRequest';
import { PruefaufgabeModelRequest } from '../model/pruefaufgabeModelRequest';
import { PruefaufgabeReihenfolgeRequest } from '../model/pruefaufgabeReihenfolgeRequest';
import { PruefaufgabeRequest } from '../model/pruefaufgabeRequest';
import { PruefchecklisteModelRequest } from '../model/pruefchecklisteModelRequest';
import { PruefchecklisteReihenfolgeRequest } from '../model/pruefchecklisteReihenfolgeRequest';
import { PruefchecklisteRequest } from '../model/pruefchecklisteRequest';
import { PruefobjektModelRequest } from '../model/pruefobjektModelRequest';
import { PruefobjektRequest } from '../model/pruefobjektRequest';
import { PruefplanModelRequest } from '../model/pruefplanModelRequest';
import { PruefplanRequest } from '../model/pruefplanRequest';
import { PruefplanRolleMultiRequest } from '../model/pruefplanRolleMultiRequest';
import { PruefplanRolleRequest } from '../model/pruefplanRolleRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminCommandService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostPruefAntwortAktualisieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefAntwortAktualisieren(body?: PruefAntwortModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefAntwortAktualisieren(body?: PruefAntwortModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefAntwortAktualisieren(body?: PruefAntwortModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefAntwortAktualisieren(body?: PruefAntwortModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefantwort/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefaufgabeAktualisieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefaufgabeAktualisieren(body?: PruefaufgabeModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefaufgabeAktualisieren(body?: PruefaufgabeModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefaufgabeAktualisieren(body?: PruefaufgabeModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefaufgabeAktualisieren(body?: PruefaufgabeModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefaufgabe/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefaufgabeEntfernen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefaufgabeEntfernen(body?: PruefaufgabeRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefaufgabeEntfernen(body?: PruefaufgabeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefaufgabeEntfernen(body?: PruefaufgabeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefaufgabeEntfernen(body?: PruefaufgabeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefaufgabe/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefaufgabeHinzufuegen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefaufgabeHinzufuegen(body?: PruefaufgabeModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefaufgabeHinzufuegen(body?: PruefaufgabeModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefaufgabeHinzufuegen(body?: PruefaufgabeModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefaufgabeHinzufuegen(body?: PruefaufgabeModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefaufgabe/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefaufgabeKopieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefaufgabeKopieren(body?: PruefaufgabeRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefaufgabeKopieren(body?: PruefaufgabeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefaufgabeKopieren(body?: PruefaufgabeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefaufgabeKopieren(body?: PruefaufgabeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefaufgabe/kopieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefaufgabeReihenfolgeSetzen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefaufgabeReihenfolgeSetzen(body?: PruefaufgabeReihenfolgeRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefaufgabeReihenfolgeSetzen(body?: PruefaufgabeReihenfolgeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefaufgabeReihenfolgeSetzen(body?: PruefaufgabeReihenfolgeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefaufgabeReihenfolgeSetzen(body?: PruefaufgabeReihenfolgeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefaufgabe/reihenfolge`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefchecklisteAktualisieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefchecklisteAktualisieren(body?: PruefchecklisteModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefchecklisteAktualisieren(body?: PruefchecklisteModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefchecklisteAktualisieren(body?: PruefchecklisteModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefchecklisteAktualisieren(body?: PruefchecklisteModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefcheckliste/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefchecklisteEntfernen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefchecklisteEntfernen(body?: PruefchecklisteRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefchecklisteEntfernen(body?: PruefchecklisteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefchecklisteEntfernen(body?: PruefchecklisteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefchecklisteEntfernen(body?: PruefchecklisteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefcheckliste/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefchecklisteHinzufuegen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefchecklisteHinzufuegen(body?: PruefchecklisteModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefchecklisteHinzufuegen(body?: PruefchecklisteModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefchecklisteHinzufuegen(body?: PruefchecklisteModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefchecklisteHinzufuegen(body?: PruefchecklisteModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefcheckliste/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefchecklisteKopieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefchecklisteKopieren(body?: PruefchecklisteRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefchecklisteKopieren(body?: PruefchecklisteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefchecklisteKopieren(body?: PruefchecklisteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefchecklisteKopieren(body?: PruefchecklisteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefcheckliste/kopieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefchecklisteReihenfolgeSetzen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefchecklisteReihenfolgeSetzen(body?: PruefchecklisteReihenfolgeRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefchecklisteReihenfolgeSetzen(body?: PruefchecklisteReihenfolgeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefchecklisteReihenfolgeSetzen(body?: PruefchecklisteReihenfolgeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefchecklisteReihenfolgeSetzen(body?: PruefchecklisteReihenfolgeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefcheckliste/reihenfolge`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefobjektAktualisieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefobjektAktualisieren(body?: PruefobjektModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefobjektAktualisieren(body?: PruefobjektModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefobjektAktualisieren(body?: PruefobjektModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefobjektAktualisieren(body?: PruefobjektModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefobjekt/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefobjektEntfernen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefobjektEntfernen(body?: PruefobjektRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefobjektEntfernen(body?: PruefobjektRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefobjektEntfernen(body?: PruefobjektRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefobjektEntfernen(body?: PruefobjektRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefobjekt/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefobjektHinzufuegen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefobjektHinzufuegen(body?: PruefobjektModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefobjektHinzufuegen(body?: PruefobjektModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefobjektHinzufuegen(body?: PruefobjektModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefobjektHinzufuegen(body?: PruefobjektModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefobjekt/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefobjektKopieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefobjektKopieren(body?: PruefobjektRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefobjektKopieren(body?: PruefobjektRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefobjektKopieren(body?: PruefobjektRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefobjektKopieren(body?: PruefobjektRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefobjekt/kopieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanAktualisieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanAktualisieren(body?: PruefplanModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanAktualisieren(body?: PruefplanModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanAktualisieren(body?: PruefplanModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanAktualisieren(body?: PruefplanModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanEntfernen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanEntfernen(body?: PruefplanRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanEntfernen(body?: PruefplanRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanEntfernen(body?: PruefplanRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanEntfernen(body?: PruefplanRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanHinzufuegen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanHinzufuegen(body?: PruefplanModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanHinzufuegen(body?: PruefplanModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanHinzufuegen(body?: PruefplanModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanHinzufuegen(body?: PruefplanModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanKopieren (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanKopieren(body?: PruefplanRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanKopieren(body?: PruefplanRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanKopieren(body?: PruefplanRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanKopieren(body?: PruefplanRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/kopieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanRolleControllerEntfernen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanRolleControllerEntfernen(body?: PruefplanRolleRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanRolleControllerEntfernen(body?: PruefplanRolleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanRolleControllerEntfernen(body?: PruefplanRolleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanRolleControllerEntfernen(body?: PruefplanRolleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/rollen/controller/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanRollenControllerHinzufuegen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanRollenControllerHinzufuegen(body?: PruefplanRolleMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanRollenControllerHinzufuegen(body?: PruefplanRolleMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanRollenControllerHinzufuegen(body?: PruefplanRolleMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanRollenControllerHinzufuegen(body?: PruefplanRolleMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/rollen/controller/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanRollenPrueferEntfernen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanRollenPrueferEntfernen(body?: PruefplanRolleRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanRollenPrueferEntfernen(body?: PruefplanRolleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanRollenPrueferEntfernen(body?: PruefplanRolleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanRollenPrueferEntfernen(body?: PruefplanRolleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/rollen/pruefer/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPruefplanRollenPrueferHinzufuegen (Auth)
     * ORGA App Zugriff:  * QsChecks - Zugriff  | Admin  * Mandant: ValidUser  * Lizenz: qschecks  * Ist Qs Checks Admin in Funktionsbereich  
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPruefplanRollenPrueferHinzufuegen(body?: PruefplanRolleMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPruefplanRollenPrueferHinzufuegen(body?: PruefplanRolleMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPruefplanRollenPrueferHinzufuegen(body?: PruefplanRolleMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPruefplanRollenPrueferHinzufuegen(body?: PruefplanRolleMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/admin/pruefplan/rollen/pruefer/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
