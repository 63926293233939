/**
 * orga app | veranstaltungen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AufgabeAdmin } from '../model/aufgabeAdmin';
import { Benutzer } from '../model/benutzer';
import { Checkliste } from '../model/checkliste';
import { ChecklisteAufgabenAdmin2 } from '../model/checklisteAufgabenAdmin2';
import { DienstplanFunktionsbereich } from '../model/dienstplanFunktionsbereich';
import { Dokument } from '../model/dokument';
import { Funktionsbereich } from '../model/funktionsbereich';
import { FunktionsbereichChecklisten } from '../model/funktionsbereichChecklisten';
import { FunktionsbereichRolle } from '../model/funktionsbereichRolle';
import { FunktionsbereichRollen } from '../model/funktionsbereichRollen';
import { Rolle } from '../model/rolle';
import { RolleBenutzer } from '../model/rolleBenutzer';
import { Veranstaltung } from '../model/veranstaltung';
import { VeranstaltungDetail } from '../model/veranstaltungDetail';
import { VeranstaltungsTyp } from '../model/veranstaltungsTyp';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetChecklisteAbarbeiterRollenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChecklisteAbarbeiterRollenAdmin(checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRolle>>;
    public getChecklisteAbarbeiterRollenAdmin(checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRolle>>>;
    public getChecklisteAbarbeiterRollenAdmin(checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRolle>>>;
    public getChecklisteAbarbeiterRollenAdmin(checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getChecklisteAbarbeiterRollenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRolle>>('get',`${this.basePath}/api/query/veranstaltungen/admin/checklisten/${encodeURIComponent(String(checkliste_id))}/abarbeiterrollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetChecklisteAufgabenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChecklisteAufgabenAdmin(checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AufgabeAdmin>>;
    public getChecklisteAufgabenAdmin(checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AufgabeAdmin>>>;
    public getChecklisteAufgabenAdmin(checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AufgabeAdmin>>>;
    public getChecklisteAufgabenAdmin(checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getChecklisteAufgabenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AufgabeAdmin>>('get',`${this.basePath}/api/query/veranstaltungen/admin/checklisten/${encodeURIComponent(String(checkliste_id))}/aufgaben`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetChecklisteFreigabeRollenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChecklisteFreigabeRollenAdmin(checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRolle>>;
    public getChecklisteFreigabeRollenAdmin(checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRolle>>>;
    public getChecklisteFreigabeRollenAdmin(checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRolle>>>;
    public getChecklisteFreigabeRollenAdmin(checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getChecklisteFreigabeRollenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRolle>>('get',`${this.basePath}/api/query/veranstaltungen/admin/checklisten/${encodeURIComponent(String(checkliste_id))}/freigaberollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetChecklisteLeseRollenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChecklisteLeseRollenAdmin(checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRolle>>;
    public getChecklisteLeseRollenAdmin(checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRolle>>>;
    public getChecklisteLeseRollenAdmin(checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRolle>>>;
    public getChecklisteLeseRollenAdmin(checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getChecklisteLeseRollenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRolle>>('get',`${this.basePath}/api/query/veranstaltungen/admin/checklisten/${encodeURIComponent(String(checkliste_id))}/leserollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichChecklistenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichChecklistenAdmin(funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Checkliste>>;
    public getFunktionsbereichChecklistenAdmin(funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Checkliste>>>;
    public getFunktionsbereichChecklistenAdmin(funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Checkliste>>>;
    public getFunktionsbereichChecklistenAdmin(funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getFunktionsbereichChecklistenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Checkliste>>('get',`${this.basePath}/api/query/veranstaltungen/admin/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/checklisten`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetRolleBenutzerAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param rolle_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolleBenutzerAdmin(rolle_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RolleBenutzer>>;
    public getRolleBenutzerAdmin(rolle_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RolleBenutzer>>>;
    public getRolleBenutzerAdmin(rolle_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RolleBenutzer>>>;
    public getRolleBenutzerAdmin(rolle_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (rolle_id === null || rolle_id === undefined) {
            throw new Error('Required parameter rolle_id was null or undefined when calling getRolleBenutzerAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<RolleBenutzer>>('get',`${this.basePath}/api/query/veranstaltungen/admin/rollen/${encodeURIComponent(String(rolle_id))}/benutzer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungBenutzerListeAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungBenutzerListeAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Benutzer>>;
    public getVeranstaltungBenutzerListeAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Benutzer>>>;
    public getVeranstaltungBenutzerListeAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Benutzer>>>;
    public getVeranstaltungBenutzerListeAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungBenutzerListeAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Benutzer>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/benutzer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDetailAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDetailAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungDetail>;
    public getVeranstaltungDetailAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungDetail>>;
    public getVeranstaltungDetailAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungDetail>>;
    public getVeranstaltungDetailAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDetailAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungDetail>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDienstplanAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDienstplanAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DienstplanFunktionsbereich>>;
    public getVeranstaltungDienstplanAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DienstplanFunktionsbereich>>>;
    public getVeranstaltungDienstplanAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DienstplanFunktionsbereich>>>;
    public getVeranstaltungDienstplanAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDienstplanAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DienstplanFunktionsbereich>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/dienstplan`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDokumenteAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDokumenteAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Dokument>>;
    public getVeranstaltungDokumenteAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Dokument>>>;
    public getVeranstaltungDokumenteAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Dokument>>>;
    public getVeranstaltungDokumenteAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDokumenteAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Dokument>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/dokumente`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChecklisteAufgabenAdmin2>>;
    public getVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChecklisteAufgabenAdmin2>>>;
    public getVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChecklisteAufgabenAdmin2>>>;
    public getVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin(veranstaltung_id: string, funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin.');
        }

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ChecklisteAufgabenAdmin2>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/aufgaben/berechtigungen/benutzer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereicheAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereicheAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Funktionsbereich>>;
    public getVeranstaltungFunktionsbereicheAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Funktionsbereich>>>;
    public getVeranstaltungFunktionsbereicheAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Funktionsbereich>>>;
    public getVeranstaltungFunktionsbereicheAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereicheAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Funktionsbereich>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/funktionsbereiche`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereicheChecklistenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereicheChecklistenAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichChecklisten>>;
    public getVeranstaltungFunktionsbereicheChecklistenAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichChecklisten>>>;
    public getVeranstaltungFunktionsbereicheChecklistenAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichChecklisten>>>;
    public getVeranstaltungFunktionsbereicheChecklistenAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereicheChecklistenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichChecklisten>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/funktionsbereichechecklisten`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereicheRollenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereicheRollenAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRollen>>;
    public getVeranstaltungFunktionsbereicheRollenAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRollen>>>;
    public getVeranstaltungFunktionsbereicheRollenAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRollen>>>;
    public getVeranstaltungFunktionsbereicheRollenAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereicheRollenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRollen>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/funktionsbereicherollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereicheRollenAdminAlle (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereicheRollenAdminAlle(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRollen>>;
    public getVeranstaltungFunktionsbereicheRollenAdminAlle(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRollen>>>;
    public getVeranstaltungFunktionsbereicheRollenAdminAlle(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRollen>>>;
    public getVeranstaltungFunktionsbereicheRollenAdminAlle(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereicheRollenAdminAlle.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRollen>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/funktionsbereicherollen/alle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungRollenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungRollenAdmin(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Rolle>>;
    public getVeranstaltungRollenAdmin(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Rolle>>>;
    public getVeranstaltungRollenAdmin(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Rolle>>>;
    public getVeranstaltungRollenAdmin(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungRollenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Rolle>>('get',`${this.basePath}/api/query/veranstaltungen/admin/${encodeURIComponent(String(veranstaltung_id))}/rollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenAlleAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenAlleAdmin(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getVeranstaltungenAlleAdmin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getVeranstaltungenAlleAdmin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getVeranstaltungenAlleAdmin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/admin/alle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenAngelegteAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenAngelegteAdmin(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getVeranstaltungenAngelegteAdmin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getVeranstaltungenAngelegteAdmin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getVeranstaltungenAngelegteAdmin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/admin/angelegte`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenBeendeteAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenBeendeteAdmin(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getVeranstaltungenBeendeteAdmin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getVeranstaltungenBeendeteAdmin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getVeranstaltungenBeendeteAdmin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/admin/beendete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenVeroeffentlichteAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenVeroeffentlichteAdmin(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getVeranstaltungenVeroeffentlichteAdmin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getVeranstaltungenVeroeffentlichteAdmin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getVeranstaltungenVeroeffentlichteAdmin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/admin/veroeffentlichte`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungsTypenAdmin (Auth)
     * ORGA App Zugriff:  * Mandant: ValidUser  
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungsTypenAdmin(observe?: 'body', reportProgress?: boolean): Observable<Array<VeranstaltungsTyp>>;
    public getVeranstaltungsTypenAdmin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VeranstaltungsTyp>>>;
    public getVeranstaltungsTypenAdmin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VeranstaltungsTyp>>>;
    public getVeranstaltungsTypenAdmin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VeranstaltungsTyp>>('get',`${this.basePath}/api/query/veranstaltungen/admin/veranstaltungstypen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
